<template>
    <h1>{{labels.pageHeading}}</h1>
  <h6>{{labels.pageSubheading}}</h6>
  <form class="login-form">
    <div class="field-group">
      <mcw-textfield
        v-model.trim="email"
        :label="labels.email"
        :helptext="labels.noticeValidation"
        helptext-validation
        @focus="resetError"
        required
        outline
        secondary
        :valid="isValid"
      ></mcw-textfield>
    </div>
    <div class="buttons-container">
      <mcw-button
        @click.prevent="sendResetRequest"
        raised>
        {{labels.send}}
      </mcw-button>
    </div>
    <router-link
      class="login-link"
      to='/new-password'
      v-if="success">
      {{labels.successlink}}
    </router-link>
  </form>
    <mcw-snackbar
      v-model="isMessageShown"
      :message="message"
      :dismissAction="false"
    ></mcw-snackbar>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      labels: {
        pageHeading: 'Сброс пароля',
        pageSubheading: 'Введите адрес электронной почты, на которую будет выслана ссылка для восстановления пароля. Адрес должен быть зарегистрирован в системе MedSafe.',
        email: 'Ваш E-mail',
        send: 'отправить',
        noticeValidation: 'Введите корректный адрес электронной почты',
        noticeSuccess: 'Ссылка отправлена на Вашу почту',
        successlink: 'Перейти на страницу входа',
      },
      email: '',
      isValid: true,
      reg: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
      contactType: 2, // phone: 1, email: 2
      success: false,
      message: '',
      isMessageShown: false,
    };
  },
  methods: {
    validateEmail() {
      return this.reg.test(this.email);
    },
    resetError() {
      this.isValid = true;
    },
    async sendResetRequest() {
      this.isValid = this.validateEmail();
      if (!this.isValid) {
        return false;
      }
      const request = await this.$store.dispatch('auth/sendResetRequest', { contactType: this.contactType, email: this.email });
      if (request.err !== 0) {
        this.message = request.err_text;
        this.isMessageShown = true;
      } else {
        this.showSuccessNotice();
      }
    },
    showSuccessNotice() {
      this.success = true;
      this.message = this.labels.noticeSuccess;
      this.isMessageShown = true;
    },
  },
};
</script>
